import React from 'react'
import ReactDOM from 'react-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { ThemeProvider } from '@mui/material/styles'
import './index.css'
import AppProviders from './AppProviders'
import theme from './utils/theme'
import { AUTH0_API_IDENTIFIER, AUTH0_CLIENT_ID, AUTH0_TENET_DOMAIN } from './consts'

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={`https://${AUTH0_TENET_DOMAIN}`}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={AUTH0_API_IDENTIFIER}
    >
      <ThemeProvider theme={theme}>
        <AppProviders />
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
