import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import './App.css'
import Login from './views/Login/Login'
import Logout from './views/Logout/Logout'

const MainRouter = (): JSX.Element => {  
  return (
    <div> 
      <div>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/logout" exact component={Logout} />
          <Route>
            <Redirect to={'/login'} />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default MainRouter