import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from '../../components/Loading'
import { MAIN_PAGE_URL } from '../../consts'

const Logout = (): JSX.Element => {
  const { logout } = useAuth0()

  useEffect(() => {
    logout({ returnTo: MAIN_PAGE_URL })
  }, [logout])

  return ( 
    <Loading text='Please wait while we redirect you back safely' />
  )
}

export default Logout