import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { BrowserRouter as Router } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { TIME_PRE_LOGOUT } from './consts'

import ProtectedRoute from './ProtectedRoute'
import MainRouter from './MainRouter'
import ErrorPage from './components/Error'

const AppProviders = (): JSX.Element => {
  const { error, logout } = useAuth0()
  
  const [secondesLeft, setSecondesLeft] = useState<number>(Number(TIME_PRE_LOGOUT) / 1000)

  if (error) {
    setTimeout(()=> {
      logout()
    }, Number(TIME_PRE_LOGOUT))

    setTimeout(()=> {
      setSecondesLeft(secondesLeft - 1)
    }, 1000)

    return <ErrorPage error = {error} message = {`Sign up is disabled, you will be logged out in ${secondesLeft}`} />
  }
  
  return (
    <>
      <CssBaseline />
      <Router>
        <ProtectedRoute>
          <MainRouter />
        </ProtectedRoute>
      </Router>
    </>
  )
}

export default AppProviders