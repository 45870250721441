import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { getRedirectUrl } from '../../lib/get-redirect-url'
import Loading from '../../components/Loading'
import ErrorPage from '../../components/Error'
import { AUTH0_API_IDENTIFIER } from '../../consts'

const Authenticator = (): JSX.Element => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const [error, setError] = useState<any>(undefined)

  useEffect(() => {
    const onLoginSuccess = async (): Promise<void> => {
      const accessToken = await getAccessTokenSilently({
        audience: `${AUTH0_API_IDENTIFIER}`
      })

      try {
        const url = await getRedirectUrl(accessToken)

        window.location.replace(url)
        
      } catch (error) {
        setError(error)
      }
      
    }
    
    if (isAuthenticated) {
      onLoginSuccess()
    }
  }, [getAccessTokenSilently, isAuthenticated])

  return ( 
    error? <ErrorPage error={error} /> : <Loading text='Please wait while we redirect you back safely' />
  )
}

export default Authenticator