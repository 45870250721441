import axios from 'axios'
import { AUTH_URL } from '../consts'

export const getRedirectUrl = async (accessToken: string): Promise<string> => {
  const { data: { url } } = await axios({
    method: 'get',
    url: `${AUTH_URL}/login`,
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })

    
  return url
}